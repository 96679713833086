var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoading,"variant":"dark","opacity":0.25,"rounded":"sm"}},[_c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t(("apps.transactionsCashbank.singular." + (_vm.typeIn.includes(_vm.transactionTypeCode)
                        ? 'depositTo' : 'payFrom'))),"label-for":"cashbank-account"}},[_c('validation-provider',{attrs:{"name":"cashbank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [(_vm.companyActive.plan_id > 2)?_c('v-select',{ref:"firstFocusSelectInput",attrs:{"id":"cashbank-account","options":_vm.LOV.cashbankCodeAccounts,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.cashbankId),callback:function ($$v) {_vm.$set(_vm.form, "cashbankId", $$v)},expression:"form.cashbankId"}}):_c('v-select',{ref:"firstFocusSelectInput",attrs:{"id":"cashbank-account","options":_vm.LOV.cashbankAccounts,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.accountId),callback:function ($$v) {_vm.$set(_vm.form, "accountId", $$v)},expression:"form.accountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t(("apps.transactionsCashbank.singular." + (_vm.typeIn.includes(_vm.transactionTypeCode)
                        ? 'receiveFrom' : 'payTo'))),"label-for":"contacts"}},[_c('v-select',{attrs:{"id":"contacts","reduce":function (field) { return field.id; },"options":_vm.LOV.contacts,"label":"label","disabled":_vm.actions.isPreview},on:{"input":function (field) { return _vm.handleContact(field); }},model:{value:(_vm.form.contactId),callback:function ($$v) {_vm.$set(_vm.form, "contactId", $$v)},expression:"form.contactId"}})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.accountGeneral),expression:"form.accountGeneral"}],attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.transactionsCashbank.singular.accountName'),"label-for":"accountName"}},[_c('b-form-input',{attrs:{"id":"accountName","placeholder":"Account Name","disabled":_vm.actions.isPreview},model:{value:(_vm.form.accountName),callback:function ($$v) {_vm.$set(_vm.form, "accountName", $$v)},expression:"form.accountName"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.transactionsCashbank.singular.date'),"label-for":"date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date","config":_vm.dateFormat,"state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.transactionsCashbank.singular.code'),"label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","placeholder":"CI-2021020001","disabled":_vm.actions.isPreview},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.tags'),"label-for":"tags"}},[_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"tags","label":"name","multiple":"","options":_vm.LOV.tags,"reduce":function (field) { return field.id; },"create-option":function (field) { return ({ id: Date.now(), name: field }); },"searchable":!_vm.actions.isPreview,"selectable":function (option) { return !_vm.actions.isPreview; }},on:{"option:created":_vm.pushTags},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}})],1)],1)],1),_c('b-table',{ref:"refFormTable",staticClass:"mt-2 position-relative",attrs:{"id":"refFormTable","fixed":"","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.form.details,"primary-key":"id","show-empty":""},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true},{key:"cell(accountId)",fn:function(ref){
                    var index = ref.index;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"account","rules":"required","vid":("detail-" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account-type","options":_vm.LOV.accounts,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},on:{"option:selected":function($event){return _vm.activityChange(
                          index,
                          _vm.LOV.accounts,
                          _vm.form.details[index].accountId
                        )}},model:{value:(_vm.form.details[index].accountId),callback:function ($$v) {_vm.$set(_vm.form.details[index], "accountId", $$v)},expression:"form.details[index].accountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"cell(remark)",fn:function(ref){
                        var index = ref.index;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"description","rules":"required","vid":("detail-" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":!_vm.actions.isPreview
                          ? _vm.$t(
                              'apps.transactionsCashbank.placeholder.description'
                            )
                          : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.details[index].remark),callback:function ($$v) {_vm.$set(_vm.form.details[index], "remark", $$v)},expression:"form.details[index].remark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"cell(amount)",fn:function(ref){
                          var index = ref.index;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"amount","rules":"required","vid":("detail-" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('cleave',{key:index,staticClass:"text-right form-control",attrs:{"state":errors.length > 0 ? false : null,"options":_vm.numeric,"disabled":_vm.actions.isPreview,"placeholder":"10,000"},on:{"blur":_vm.sumAmount},model:{value:(_vm.form.details[index].amount),callback:function ($$v) {_vm.$set(_vm.form.details[index], "amount", _vm._n($$v))},expression:"form.details[index].amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"cell(actions)",fn:function(ref){
                          var index = ref.index;
return [_c('b-button',{staticClass:"btn-icon invoice-edit-input",attrs:{"variant":"flat-danger","disabled":_vm.form.details.length <= 1},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"custom-foot",fn:function(ref){return [_c('b-tr',[_c('b-td',{staticClass:"pl-0"},[(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.addMore"))+" ")],1):_vm._e()],1),_c('b-td',{staticClass:"text-right"},[_c('div',{staticClass:"mb-0 h4 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("globalSingular.total"))+" ")])]),_c('b-td',{staticClass:"text-right"},[_c('div',{staticClass:"mb-0 h4 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.totalAmount)+" ")])]),_c('b-td')],1)]}}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.transactionsCashbank.singular.notes'),"label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","rows":"1","placeholder":!_vm.actions.isPreview
                        ? _vm.$t('apps.transactionsCashbank.placeholder.description')
                        : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-transactions-cashbank-list' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.backToList"))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.delete"))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('div',[(!_vm.actions.isEditMode)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":_vm.handleSubmitAndCreate}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(((_vm.$t("globalActions.submit")) + " & " + (_vm.$t("globalActions.create"))))+" ")],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t("globalActions.update") : _vm.$t("globalActions.submit"))+" ")],1)],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.changeToEdit"))+" ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }